/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { BookingStrictHttpResponse } from '../../booking-strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { HealthCareServiceSpecialityModel } from '../../models/health-care-service-speciality-model';

export interface HealthFindrFhirIdHealthCareServiceSpecialityGet$Params {
  fhirId: string;
}

export function healthFindrFhirIdHealthCareServiceSpecialityGet(http: HttpClient, rootUrl: string, params: HealthFindrFhirIdHealthCareServiceSpecialityGet$Params, context?: HttpContext): Observable<BookingStrictHttpResponse<Array<HealthCareServiceSpecialityModel>>> {
  const rb = new RequestBuilder(rootUrl, healthFindrFhirIdHealthCareServiceSpecialityGet.PATH, 'get');
  if (params) {
    rb.path('fhirId', params.fhirId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as BookingStrictHttpResponse<Array<HealthCareServiceSpecialityModel>>;
    })
  );
}

healthFindrFhirIdHealthCareServiceSpecialityGet.PATH = '/HealthFindr/{fhirId}/HealthCareServiceSpeciality';
